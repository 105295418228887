import { League, LeagueSearchResultGroupedBySportAndGameCategory, LeagueSearchQueryType } from '@arland-bmnext/api-data'
import { HttpClient, getJson, SessionData, postJson, deleteJson } from '../core'
import { FavoriteLeagueDTO } from '@arland-bmnext/webapps-api-data'
import { SaveUserFavoritesOrderRequestModel } from './model'

export class LeaguesClient {
  constructor(private readonly httpClient: HttpClient) {}

  public async getLeagueById(sessionData: SessionData, leagueId: number, languageId: number): Promise<League> {
    const league = await getJson<League>(this.httpClient, ['api', 'leagues', leagueId, languageId], sessionData)
    return league
  }

  public async getBettableLeagues(
    sessionData: SessionData,
    sportId: number,
    categoryId: number,
    startDate: string,
    endDate: string,
    languageId: number = 1
  ): Promise<League[]> {
    const leagues = await getJson<League[]>(
      this.httpClient,
      ['api', 'leagues', 'bettable', sportId, categoryId, startDate, endDate, languageId],
      sessionData
    )
    return leagues
  }

  public async getUserFavoriteLeagues(sessionData: SessionData, languageId: number = 1): Promise<FavoriteLeagueDTO[]> {
    const favoriteLeagues = await getJson<FavoriteLeagueDTO[]>(
      this.httpClient,
      ['api', 'leagues', 'favorite', languageId],
      sessionData
    )
    return favoriteLeagues
  }

  public async addUserFavoriteLeague(sessionData: SessionData, leagueId: number): Promise<boolean> {
    return await postJson<null, boolean>(this.httpClient, ['api', 'leagues', leagueId, 'favorite'], null, sessionData)
  }

  public async removeUserFavoriteLeague(sessionData: SessionData, leagueId: number): Promise<boolean> {
    return await deleteJson<any, boolean>(this.httpClient, ['api', 'leagues', leagueId, 'favorite'], null, sessionData)
  }

  public async saveUserFavoritesOrder(
    sessionData: SessionData,
    request: SaveUserFavoritesOrderRequestModel[],
    languageId: number = 1
  ): Promise<FavoriteLeagueDTO[]> {
    return await postJson<SaveUserFavoritesOrderRequestModel[], FavoriteLeagueDTO[]>(
      this.httpClient,
      ['api', 'leagues', 'favorites', languageId],
      request,
      sessionData
    )
  }

  public async fullTextLeagueSearch(
    sessionData: SessionData,
    languageId: number,
    text: string,
    queryType: LeagueSearchQueryType = LeagueSearchQueryType.OnlyLeagues,
    pageNumber?: number,
    pageSize?: number,
    marketTypeIds?: number[]
  ): Promise<LeagueSearchResultGroupedBySportAndGameCategory> {
    const query = {} as any
    query['text'] = text
    query['queryType'] = queryType
    if (pageNumber) query['pageNumber'] = pageNumber
    if (pageSize) query['pageSize'] = pageSize
    if (marketTypeIds) query['marketTypeIds'] = marketTypeIds.join(',')

    return await getJson<LeagueSearchResultGroupedBySportAndGameCategory>(
      this.httpClient,
      ['api', 'leagues', 'search', languageId],
      sessionData,
      query
    )
  }
}
