import { getJson, HttpClient, SessionData } from '../core'
import { CardsClient } from './cards'
import { CategoriesClient } from './categories'
import { GamesClient } from './games'
import { LeaguesClient } from './leagues'
import { SportsClient } from './sports'
import { MarketTypeCategoryDTO } from '@arland-bmnext/webapps-api-data'
import { MarketType } from '@arland-bmnext/api-data'
import { SCLClient } from './scl'

export class OddsClient {
  public readonly sports: SportsClient
  public readonly categories: CategoriesClient
  public readonly leagues: LeaguesClient
  public readonly games: GamesClient
  public readonly cards: CardsClient
  public readonly scl: SCLClient

  constructor(private readonly httpClient: HttpClient) {
    this.sports = new SportsClient(httpClient)
    this.categories = new CategoriesClient(httpClient)
    this.leagues = new LeaguesClient(httpClient)
    this.games = new GamesClient(httpClient)
    this.cards = new CardsClient(httpClient)
    this.scl = new SCLClient(httpClient)
  }

  getMarketTypes(sessionData: SessionData, languageId: number): Promise<Partial<MarketType>[]> {
    const query = {} as any
    query['languageId'] = languageId

    return getJson<Partial<MarketType>[]>(this.httpClient, ['api', 'markettypes'], sessionData, query)
  }

  getMarketTypeCategories(sessionData: SessionData, languageId: number): Promise<MarketTypeCategoryDTO[]> {
    const query = {} as any
    query['languageId'] = languageId

    return getJson<MarketTypeCategoryDTO[]>(this.httpClient, ['api', 'markettypes', 'categories'], sessionData, query)
  }
}
