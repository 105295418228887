import { HttpClient, postJson, SessionData } from '../../../core'
import { AbstractPaymentHandlerClient } from '../abstract-payment-handler'
import {
  ApcoPayInDTO,
  ApcoPayInRequestDTO,
} from '@arland-bmnext/webapps-api-data'

export class ApcoPayInPaymentHandlerClient extends AbstractPaymentHandlerClient {
  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  executeTransaction(sessionData: SessionData, request: ApcoPayInRequestDTO): Promise<ApcoPayInDTO> {
    return postJson<ApcoPayInRequestDTO, ApcoPayInDTO>(
      this.httpClient,
      ['api', 'payment', 'apco', 'payin'],
      request,
      sessionData
    )
  }
}
