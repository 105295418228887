import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useLocalStorage } from './useLocalStorage'

const useIncomeAccessBtag = (): { btag: string; } => {
  const router = useRouter()
  const [btag, setBtag] = useLocalStorage<string>('btag', undefined)

  useEffect(() => {
    const btag = router?.query?.btag || router?.query?.BTAG
    if (btag) setBtag(btag as string)
  }, [router?.query])

  return { btag }
}

export default useIncomeAccessBtag
