import { HttpClient, postJson, SessionData } from '../../../core'
import { AbstractPaymentHandlerClient } from '../abstract-payment-handler'
import { ApayPayInRequestDTO, ApayPayInDTO, ApayPayInProcessDTO } from '@arland-bmnext/webapps-api-data'

export class ApayPayInPaymentHandlerClient extends AbstractPaymentHandlerClient {
  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  override processTransaction(sessionData: SessionData, request: ApayPayInRequestDTO): Promise<ApayPayInProcessDTO> {
    return postJson<ApayPayInRequestDTO, ApayPayInProcessDTO>(
      this.httpClient,
      ['api', 'payment', 'apay', 'payin', 'process'],
      request,
      sessionData
    )
  }

  executeTransaction(sessionData: SessionData, request: ApayPayInRequestDTO): Promise<ApayPayInDTO> {
    return postJson<ApayPayInRequestDTO, ApayPayInDTO>(
      this.httpClient,
      ['api', 'payment', 'apay', 'payin'],
      request,
      sessionData
    )
  }
}
