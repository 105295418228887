import { createContext, useContext } from 'react'
import { FrontEndSettingsDTO } from '@arland-bmnext/webapps-api-data'
import { FrontEndResources } from '@bmnext-cms/types/content'

type AppContextProps = {
  frontendSettings: FrontEndSettingsDTO
  frontendResources: FrontEndResources
}

export const AppContext = createContext<AppContextProps | undefined>(undefined)

export const useAppContext = () => {
  const value = useContext(AppContext)
  if (process.env.NODE_ENV !== 'production') {
    if (value === undefined) {
      throw new Error('useCommonContext must be wrapped in a <AppProvider />')
    }
  }
  return value
}

const AppProvider = ({ children, frontendSettings, frontendResources }) => {
  const value = {
    frontendSettings,
    frontendResources,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppProvider
