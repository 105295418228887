import { createContext, useContext, useEffect, useState } from 'react'
import { setBodyNotScrollable, setBodyScrollable } from '../util/toggleBodyScrollable'

type LayoutContextProps = {
  currentBreakPoint?: BreakPoint
  loadingState?: { loading: boolean; loadingText: string }
  setLoadingState?: (loading: boolean, loadingText?: string) => void
  sideNavActive?: boolean
  setSideNavActive?: (active: boolean) => void
}

type BreakPoint = 'mobile' | 'tablet' | 'desktop'
const mobileMaxWidth = 768
const tabletMaxWidth = 1280

const calcCurrentBreakPoint = () => {
  const width = window.innerWidth
  if (width >= tabletMaxWidth) return 'desktop'
  else if (width >= mobileMaxWidth) return 'tablet'
  else return 'mobile'
}

export const LayoutContext = createContext<LayoutContextProps | undefined>(undefined)

export const useLayoutContext = () => {
  const value = useContext(LayoutContext)
  if (process.env.NODE_ENV !== 'production') {
    if (value === undefined) {
      throw new Error('useLayoutContext must be wrapped in a <LayoutProvider />')
    }
  }
  return value
}

const LayoutProvider = ({ children }) => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState<BreakPoint>(calcCurrentBreakPoint())
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [sideNavActive, setSideNavActive] = useState(false)
  

  useEffect(() => {
    const handleResize = () => {
      setCurrentBreakPoint(calcCurrentBreakPoint())
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.addEventListener('resize', handleResize)
    }
  }, [])

  const setLoadingState = (loading, loadingText = '') => {
    setLoading(loading)
    setLoadingText(loadingText)
    loading === true ? setBodyNotScrollable() : setBodyScrollable()
  }

  const value = {
    currentBreakPoint,
    loadingState: { loading, loadingText },
    setLoadingState: setLoadingState,
    sideNavActive,
    setSideNavActive,
  }

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export default LayoutProvider
