import { Country, Currency, Language, TimeZone } from '@arland-bmnext/api-data'
import useSWRImmutable from 'swr/immutable'
import { defaultFetcher } from '../util/default-fetcher'
import { handleHttpErrorStatus } from './api-client'

export type CommonData = {
  languages: Language[]
  currencies: Currency[]
  timeZones: TimeZone[]
  countries: Country[]
  nodeCountries: Country[]
}

export const useCommon = (): CommonData => {
  const { data, error } = useSWRImmutable<CommonData>('/api/common', defaultFetcher)

  handleHttpErrorStatus(error?.status)

  return data
}
