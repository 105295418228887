import { emptyGuid } from './guid'

export const getCdnImageUrl = (imageGuid: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_CDN_BASE_URL

  if (baseUrl == null || imageGuid == emptyGuid) return null

  return `${baseUrl}/images/${imageGuid}`
}

export const getMinIoImageUrl = (imageGuid: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_MINIO_BASE_URL

  if (baseUrl == null) return null

  return `${baseUrl}/next-cms/${imageGuid}`
}
