import { HttpClient, postJson, SessionData } from '../../core'
import {
  InitialTransactionRequestDTO,
  ProcessTransactionRequestDTO,
  PaymentTransactionDTO,
} from '@arland-bmnext/webapps-api-data'

export abstract class AbstractPaymentHandlerClient {
  protected readonly httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  initTransaction(sessionData: SessionData, request: InitialTransactionRequestDTO): Promise<PaymentTransactionDTO> {
    return postJson<InitialTransactionRequestDTO, PaymentTransactionDTO>(
      this.httpClient,
      ['api', 'payment', 'init'],
      request,
      sessionData
    )
  }

  processTransaction(sessionData: SessionData, request: ProcessTransactionRequestDTO): Promise<any> {
    return postJson<ProcessTransactionRequestDTO, any>(
      this.httpClient,
      ['api', 'payment', 'process'],
      request,
      sessionData
    )
  }

  abstract executeTransaction(sessionData: SessionData, request: any): Promise<any>
}
