import { GtmSettingsDTO } from '@arland-bmnext/webapps-api-data'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { isLoggedInCustomer, useUser } from '../lib/user'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
    gtag: Function
  }
}

const useGoogleTagManager = (
  googleTagManagerSettings: GtmSettingsDTO,
  consentRequired: boolean,
  performanceCookiesAccepted?: boolean,
) => {
  const { user } = useUser()
  const enabled = googleTagManagerSettings?.enabled && (!consentRequired || performanceCookiesAccepted)

  useEffect(() => {
    if (enabled && user) {
      TagManager.initialize({
        gtmId: googleTagManagerSettings?.code,
        dataLayer: {
          userId: isLoggedInCustomer(user) ? user.id : undefined,
        },
      })
    }
  }, [googleTagManagerSettings?.enabled, performanceCookiesAccepted, consentRequired, user?.id])
}

export default useGoogleTagManager
