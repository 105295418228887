export const defaultFetcher = async (url: string) => {
  const res = await fetch(url)

  if (!res.ok) {
    const error: any = new Error('An error occurred while fetching the data.')
    error.status = res.status
    throw error
  }

  return res.json()
}