import { HttpClient, postJson, SessionData } from '../../../core'
import { AbstractPaymentHandlerClient } from '../abstract-payment-handler'
import {
  Pay4FunPayInDTO,
  Pay4FunPayInRequestDTO,
} from '@arland-bmnext/webapps-api-data'

export class Pay4FunPayInPaymentHandlerClient extends AbstractPaymentHandlerClient {
  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  executeTransaction(sessionData: SessionData, request: Pay4FunPayInRequestDTO): Promise<Pay4FunPayInDTO> {
    return postJson<Pay4FunPayInRequestDTO, Pay4FunPayInDTO>(
      this.httpClient,
      ['api', 'payment', 'pay4fun', 'payin'],
      request,
      sessionData
    )
  }
}
