import { HttpClient, postJson, SessionData } from '../../../core'
import { AbstractPaymentHandlerClient } from '../abstract-payment-handler'
import {
  PaybrokersPayOutRequestDTO,
  PaybrokersPayOutDTO,
  PaymentTransactionDTO,
  PaybrokersPayOutProcessRequestDTO,
} from '@arland-bmnext/webapps-api-data'

export class PaybrokersPayOutPaymentHandlerClient extends AbstractPaymentHandlerClient {
  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  override processTransaction(
    sessionData: SessionData,
    request: PaybrokersPayOutProcessRequestDTO
  ): Promise<PaymentTransactionDTO> {
    return postJson<PaybrokersPayOutProcessRequestDTO, PaymentTransactionDTO>(
      this.httpClient,
      ['api', 'payment', 'paybrokers', 'payout', 'process'],
      request,
      sessionData
    )
  }

  executeTransaction(sessionData: SessionData, request: PaybrokersPayOutRequestDTO): Promise<PaybrokersPayOutDTO> {
    return postJson<PaybrokersPayOutRequestDTO, PaybrokersPayOutDTO>(
      this.httpClient,
      ['api', 'payment', 'paybrokers', 'payout'],
      request,
      sessionData
    )
  }
}
