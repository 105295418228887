export const truncateBefore = (value: string, pattern: string) => {
  return value.slice(value.indexOf(pattern) + pattern.length)
}

export const truncateAfter = (value: string, pattern: string) => {
  const patternIndex = value.indexOf(pattern)
  if (patternIndex < 0) return value
  return value.slice(0, patternIndex)
}

export const isNullOrEmpty = (value: any): boolean => {
  return (
    value == null ||
    value === '' ||
    (value.constructor === Array && value?.length === 0) ||
    (value.constructor === Object && Object.keys(value as any)?.length == 0)
  )
}

export const fromBase64String = (str: string) => {
  const buffer = Buffer.from(str, 'base64')
  return buffer.toString()
}

export const isValidEmail = (value: string) => {
  return value.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

export const toNormalForm = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const maskString = (str: string) => {
  if (isNullOrEmpty(str) || str.length <= 4) return str

  const visibleStart = Math.min(str.length / 4, 3)
  const visibleEnd = Math.max((str.length / 4) * 3, str.length - 3)

  if (
    visibleStart < 0 ||
    visibleEnd < 0 ||
    visibleStart >= str.length ||
    visibleEnd >= str.length ||
    visibleStart > visibleEnd
  ) {
    throw new Error('Invalid start or end positions')
  }

  const prefix = str.slice(0, visibleStart)
  const suffix = str.slice(visibleEnd + 1)
  const maskLength = str.length - (prefix.length + suffix.length)

  return `${prefix}${'*'.repeat(maskLength)}${suffix}`
}
