import { useSportsBettingSettings } from '../lib/content'

export const lmtDefaultTabs = {
  tabs: {
    1: ['statistics', 'headToHead', 'standings', 'timeline', 'lineups'], // soccer
    2: ['statistics', 'headToHead', 'standings', 'timeline', 'lineups', 'boxscore'], // basket
    3: ['statistics', 'boxScore', 'headToHead', 'standings', 'probabilities', 'timeline'], // baseball
    4: ['statistics', 'headToHead', 'standings', 'timeline'], // hockey
    5: ['statistics', 'headToHead', 'standings', 'timeline', 'pointByPoint'], // tennis
    6: ['statistics', 'headToHead', 'standings', 'timeline', 'lineups'], // handball
    12: ['statistics', 'headToHead', 'standings', 'timeline'], // rugby
    16: ['statistics', 'headToHead', 'standings', 'boxscore', 'probabilities', 'lineups'], // football
    19: ['statistics', 'headToHead', 'standings', 'timeline'], // snooker
    20: ['statistics', 'headToHead', 'standings'], // table tennis
    21: ['scorecard', 'statistics', 'headToHead', 'standings', 'lineups', 'probabilities'], // cricket
    22: ['statistics', 'headToHead', 'standings', 'timeline'], // darts
    23: ['statistics', 'headToHead', 'standings'], // volleyball
    29: ['statistics', 'headToHead', 'standings', 'timeline'], // futsal
    31: ['statistics', 'headToHead', 'standings'], // badminton
    34: ['statistics', 'headToHead', 'standings'], // beach volleyball
    40: ['track', 'leaderboard', 'standings', 'timeline', 'headToHead'], // formula
    137: ['statistics', 'headToHead', 'standings', 'timeline', 'lineups'], // esport_soccer
    153: ['statistics', 'headToHead', 'standings', 'timeline'], // esport_basketball
    195: ['statistics', 'headToHead', 'standings', 'timeline'], // esport_ice_hockey
  },
}

export const lmtTabsWithoutHeadToHead = {
  tabs: {
    1: ['statistics', 'standings', 'timeline', 'lineups'], // soccer
    2: ['statistics', 'standings', 'timeline', 'lineups', 'boxscore'], // basket
    3: ['statistics', 'boxScore', 'standings', 'probabilities', 'timeline'], // baseball
    4: ['statistics', 'standings', 'timeline'], // hockey
    5: ['statistics', 'standings', 'timeline', 'pointByPoint'], // tennis
    6: ['statistics', 'standings', 'timeline', 'lineups'], // handball
    12: ['statistics', 'standings', 'timeline'], // rugby
    16: ['statistics', 'standings', 'boxscore', 'probabilities', 'lineups'], // football
    19: ['statistics', 'standings', 'timeline'], // snooker
    20: ['statistics', 'standings'], // table tennis
    21: ['scorecard', 'statistics', 'standings', 'lineups', 'probabilities'], // cricket
    22: ['statistics', 'standings', 'timeline'], // darts
    23: ['statistics', 'standings'], // volleyball
    29: ['statistics', 'standings', 'timeline'], // futsal
    31: ['statistics', 'standings'], // badminton
    34: ['statistics', 'standings'], // beach volleyball
    40: ['track', 'leaderboard', 'standings', 'timeline'], // formula
    137: ['statistics', 'standings', 'timeline', 'lineups'], // esport_soccer
    153: ['statistics', 'standings', 'timeline'], // esport_basketball
    195: ['statistics', 'standings', 'timeline'], // esport_ice_hockey
  },
}

const useSportradarWidgetSettings = () => {
  const sportsBettingSettings = useSportsBettingSettings()

  const clientId = sportsBettingSettings?.sportRadar?.clientId
  const lmtEnabled = sportsBettingSettings?.sportRadar?.matchTrackerEnabled
  const headToHeadEnabled = sportsBettingSettings?.sportRadar?.h2hEnabled
  const betAssistEnabled = sportsBettingSettings?.sportRadar?.betAssistEnabled
  const tournamentPreviewEnabled = sportsBettingSettings?.sportRadar?.tournamentPreviewEnabled
  const enabled = lmtEnabled || headToHeadEnabled || betAssistEnabled || tournamentPreviewEnabled

  return {
    enabled,
    clientId,
    lmtEnabled,
    headToHeadEnabled,
    betAssistEnabled,
    tournamentPreviewEnabled,
  }
}

export default useSportradarWidgetSettings

export declare type SirLmtProps = {
  matchId: number
  disableWidgetHeader?: boolean
  disablePitch?: boolean
  layout?: 'single' | 'double' | 'topdown'
  disableComponents?: boolean
  momentum?: 'disable' | 'bars' | 'line' | 'timeline'
  scoreboard?: 'disable' | 'extended' | 'compact'
  collapseTo?: 'disable' | 'scoreboard' | 'momentum' | 'pitch'
  expanded?: boolean
  tabsPosition?: 'top' | 'bottom' | 'disable'
  detailedScoreboard?: 'all' | 'momentum' | 'scoreDetails' | 'disable'
  activeSwitcher?: 'momentum' | 'scoreDetails'
  ballSpottingVisibleLines?: number
  tabs?: any
  timelineLeftRightLayout?: boolean
  accessibilityEnabled?: boolean
  accessibilityLiveRegionsEnabled?: boolean
  accessibilityHeadingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  branding?: any
  disablePitchNoise?: boolean
  disablePitchStripes?: boolean
  disablePitchOverlay?: boolean
  disablePitchSpotlights?: boolean
  pitchBgImage?: string
  pitchCustomBgColor?: string
  customBrandColor?: string
  pitchBrandBgColor?: string
  pitchLightStyle?: boolean
  pitchLogo?: string
  dataByLogoColor?: string
  logo?: string[]
  logoLink?: string
  goalBannerImage?: string
  goalBannerCustomBgColor?: string
  flashLogoOnSlide?: boolean
  showOdds?: boolean
  disableDeeplink?: boolean
  disableEventStats?: boolean
  disablePitchTeamColors?: boolean
  disableFooterStats?: boolean
  oddsDeeplinkLabels?: any
  deeplinkParams?: any
  onTrack?: (a, b) => void
  silent?: boolean
}
export declare type SirBetAssistProps = {
  matchId: number
  market: string
  position?: 'right' | 'left' | 'bottom'
  width?: number
  label?: string
  icon?: string
}
export declare type SirHeadToHeadProps = {
  matchId: number
  layout?: 'grid' | 'inline'
  disableScoreboard?: boolean
  disableScoreboardTeamColors?: boolean
  statisticsEnableSeasonStats?: boolean
  statisticsEnableMatchStats?: boolean
  gamePulseIconVersion?: number
  s5ClientAlias?: string
  enableShowMore?: boolean
  gamePulseIconSet?: { veryLow: string; low: string; medium: string; high: string; veryHigh: string }
}
export declare type SirTournamentPreviewProps = {
  uniqueTournamentId: number
  matchId?: number
  seasonId?: number
  isMobile?: boolean
  disableEventTicker?: boolean
  openMoreStatsInNewTab?: boolean
  isExpanded?: boolean
  disableMoreStats?: boolean
  disableCompare?: boolean
  onItemClick?: (matchId: number) => any
  branding?: any
  s5ClientAlias?: string
}
