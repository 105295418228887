import { HttpClient, postJson, SessionData } from '../../../core'
import { AbstractPaymentHandlerClient } from '../abstract-payment-handler'
import { PaybrokersPayInRequestDTO, PaybrokersPayInDTO } from '@arland-bmnext/webapps-api-data'

export class PaybrokersPayInPaymentHandlerClient extends AbstractPaymentHandlerClient {
  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  executeTransaction(sessionData: SessionData, request: PaybrokersPayInRequestDTO): Promise<PaybrokersPayInDTO> {
    return postJson<PaybrokersPayInRequestDTO, PaybrokersPayInDTO>(
      this.httpClient,
      ['api', 'payment', 'paybrokers', 'payin'],
      request,
      sessionData
    )
  }
}
