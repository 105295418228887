import { getJson, HttpClient, SessionData } from '../core'
import {
  AccountSettingsDTO,
  AnalyticsSettingsDTO,
  CasinoCategoriesDTO,
  CasinoSettingsDTO,
  FrontEndSettingsDTO,
  SportBettingSettingsDTO,
} from '@arland-bmnext/webapps-api-data'
import { FrontEndMarketTypeCategory, FrontEndMarketTypeTranslation } from '@bmnext-cms/types/content'

export class ContentClient {
  constructor(private readonly httpClient: HttpClient) {}

  getContentByIdentifier(
    sessionData: SessionData,
    identifier: string,
    languageId: number,
    onlyPublished: boolean = true,
  ): Promise<any> {
    const query = {} as any
    query['onlyPublished'] = onlyPublished

    return getJson<any>(this.httpClient, ['api', 'content', identifier, languageId], sessionData, query)
  }

  getSportsBettingSettings(sessionData: SessionData): Promise<SportBettingSettingsDTO> {
    return getJson<SportBettingSettingsDTO>(
      this.httpClient,
      ['api', 'content', 'settings', 'sportbetting'],
      sessionData,
    )
  }

  getSidenavSettings(sessionData: SessionData, languageId: number): Promise<any> {
    const query = {} as any
    query['languageId'] = languageId

    return getJson<any>(this.httpClient, ['api', 'content', 'settings', 'sidenav'], sessionData, query)
  }

  getPaymentSettings(sessionData: SessionData): Promise<any> {
    return getJson<any>(this.httpClient, ['api', 'content', 'settings', 'payment'], sessionData)
  }

  getHtmlHeadSection(sessionData: SessionData): Promise<string> {
    return getJson<string>(this.httpClient, ['api', 'content', 'htmlhead'], sessionData)
  }

  getMarketTypeCategories(
    sessionData: SessionData,
    gameMode: 'prematch' | 'live',
    type: 'quick' | 'regular',
    languageId: number,
  ): Promise<FrontEndMarketTypeCategory[]> {
    return getJson<FrontEndMarketTypeCategory[]>(
      this.httpClient,
      ['api', 'content', 'markettypecategories', gameMode, type, languageId],
      sessionData,
    )
  }

  getMarketTypeTranslations(sessionData: SessionData, languageId: number): Promise<FrontEndMarketTypeTranslation[]> {
    const query = {} as any
    query['languageId'] = languageId

    return getJson<FrontEndMarketTypeTranslation[]>(
      this.httpClient,
      ['api', 'content', 'markettypes'],
      sessionData,
      query,
    )
  }

  getAnalyticSettings(sessionData: SessionData): Promise<AnalyticsSettingsDTO> {
    return getJson<AnalyticsSettingsDTO>(this.httpClient, ['api', 'content', 'settings', 'analytics'], sessionData)
  }

  getFrontendSettings(sessionData: SessionData): Promise<FrontEndSettingsDTO> {
    return getJson<FrontEndSettingsDTO>(this.httpClient, ['api', 'content', 'settings', 'frontend'], sessionData)
  }

  getTheme(sessionData: SessionData): Promise<any> {
    return getJson<any>(this.httpClient, ['api', 'content', 'theme'], sessionData)
  }

  getFrontendResources(sessionData: SessionData): Promise<any> {
    return getJson<any>(this.httpClient, ['api', 'content', 'resources'], sessionData)
  }

  getAccountSettings(sessionData: SessionData): Promise<AccountSettingsDTO> {
    return getJson<AccountSettingsDTO>(this.httpClient, ['api', 'content', 'settings', 'account'], sessionData)
  }

  getLiveApiDomain(sessionData: SessionData): Promise<string> {
    return getJson<string>(this.httpClient, ['api', 'domain', 'live'], sessionData)
  }

  getCdnDomain(sessionData: SessionData): Promise<string> {
    return getJson<string>(this.httpClient, ['api', 'domain', 'cdn'], sessionData)
  }

  getCasinoSettings(sessionData: SessionData): Promise<CasinoSettingsDTO> {
    return getJson<CasinoSettingsDTO>(this.httpClient, ['api', 'content', 'settings', 'casino'], sessionData)
  }

  getCasinoCategories(sessionData: SessionData, languageId: number, maxEntries: number): Promise<CasinoCategoriesDTO> {
    const query = {} as any
    query['languageId'] = languageId
    query['maxEntries'] = maxEntries

    return getJson<CasinoCategoriesDTO>(this.httpClient, ['api', 'content', 'casinocategories'], sessionData, query)
  }

  getSitemapInfo(sessionData: SessionData): Promise<any> {
    const query = {} as any
    query['isSiteMap'] = true
    query['onlyPublished'] = true

    return getJson<any>(this.httpClient, ['api', 'content'], sessionData, query)
  }
}
