import { GameCategory, GameType, GetSportEntityState, League, Sport } from '@arland-bmnext/api-data'
import { HttpClient, getJson, SessionData } from '../core'

export class SCLClient {
  constructor(private readonly httpClient: HttpClient) {}

  public async getSports(
    sessionData: SessionData,
    startDate: string,
    endDate: string,
    languageId: number,
    state: GetSportEntityState,
    isTop: boolean | undefined = undefined,
    gameTypes: GameType[] | undefined = undefined
  ): Promise<Sport[]> {
    const query = {} as any
    query['state'] = state
    if (isTop != null) query['isTop'] = isTop
    if (gameTypes != null) query['gameTypes'] = gameTypes.join(',')

    const sports = await getJson<Sport[]>(
      this.httpClient,
      ['api', 'scl', 'sports', startDate, endDate, languageId],
      sessionData,
      query
    )

    return sports
  }

  public async getCategories(
    sessionData: SessionData,
    sportId: number,
    startDate: string,
    endDate: string,
    languageId: number,
    state: GetSportEntityState,
    isTop: boolean | undefined = undefined,
    gameTypes: GameType[] | undefined = undefined
  ): Promise<GameCategory[]> {
    const query = {} as any
    query['state'] = state
    if (isTop != null) query['isTop'] = isTop
    if (gameTypes != null) query['gameTypes'] = gameTypes.join(',')

    const categories = await getJson<GameCategory[]>(
      this.httpClient,
      ['api', 'scl', 'sports', sportId, 'categories', startDate, endDate, languageId],
      sessionData,
      query
    )

    return categories
  }

  public async getLeagues(
    sessionData: SessionData,
    sportId: number,
    categoryId: number,
    startDate: string,
    endDate: string,
    languageId: number,
    state: GetSportEntityState,
    isTop: boolean | undefined = undefined,
    gameTypes: GameType[] | undefined = undefined
  ): Promise<League[]> {
    const query = {} as any
    query['state'] = state
    if (isTop != null) query['isTop'] = isTop
    if (gameTypes != null) query['gameTypes'] = gameTypes.join(',')

    const leagues = await getJson<League[]>(
      this.httpClient,
      ['api', 'scl', 'sports', sportId, 'categories', categoryId, 'leagues', startDate, endDate, languageId],
      sessionData,
      query
    )

    return leagues
  }
}
