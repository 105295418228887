import { HttpClient, postJson, SessionData } from '../../../core'
import { AbstractPaymentHandlerClient } from '../abstract-payment-handler'
import { ApayPayoutRequestDTO, ApayPayoutDTO } from '@arland-bmnext/webapps-api-data'

export class ApayPayOutPaymentHandlerClient extends AbstractPaymentHandlerClient {
  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  executeTransaction(sessionData: SessionData, request: ApayPayoutRequestDTO): Promise<ApayPayoutDTO> {
    return postJson<ApayPayoutRequestDTO, ApayPayoutDTO>(
      this.httpClient,
      ['api', 'payment', 'apay', 'payout'],
      request,
      sessionData
    )
  }
}
