import { useRouter } from 'next/router'
import { useEffect } from 'react'
import * as gtag from '../lib/gtag'
import { GaSettingsDTO } from '@arland-bmnext/webapps-api-data'

const useGoogleAnalytics = (
  googleAnalyticsSettings: GaSettingsDTO,
  consentRequired: boolean,
  performanceCookiesAccepted: boolean
) => {
  const router = useRouter()
  const enabled = googleAnalyticsSettings?.enabled && (!consentRequired || performanceCookiesAccepted)

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (enabled) {
        gtag.pageview(url, googleAnalyticsSettings.code)
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, performanceCookiesAccepted, consentRequired, performanceCookiesAccepted])
}

export default useGoogleAnalytics
