import { Identifiable, SessionData, SessionId } from './types'
import {
  isSuccessResponse,
  HttpClient,
  ErrorResponse,
  HttpQuery,
} from './httpclient'

class ClientError extends Error {
  constructor(public readonly resp: ErrorResponse) {
    super(
      `Api responded with ${resp.status}: ${resp.statusCode} ${resp.statusText}`
    )
    this.name = 'ClientError'
  }
}

export interface ReadOnlyDataClient<TObject extends Identifiable> {
  getAll(sessionData: SessionData): Promise<TObject[]>
  getById(sessionData: SessionData, id: number): Promise<TObject>
}

export type PathItem = string | number | boolean

function buildUrl(pathItems: PathItem[]): string {
  return pathItems.map((i) => i?.toString()).join('/')
}

export async function getJson<Result>(
  httpClient: HttpClient,
  pathItems: PathItem[],
  sessionData?: SessionData,
  query?: HttpQuery
): Promise<Result> {
  const res = await httpClient.getJson<Result>(
    buildUrl(pathItems),
    sessionData,
    query
  )
  if (isSuccessResponse(res)) return res.body
  throw new ClientError(res)
}

export async function putJson<Request, Result>(
  httpClient: HttpClient,
  pathItems: PathItem[],
  request: Request,
  sessionData?: SessionData,
  query?: HttpQuery
): Promise<Result> {
  const res = await httpClient.putJson<Request, Result>(
    buildUrl(pathItems),
    request,
    sessionData,
    query
  )
  if (isSuccessResponse(res)) return res.body
  throw new ClientError(res)
}

export async function deleteJson<Request, Result>(
  httpClient: HttpClient,
  pathItems: PathItem[],
  request: Request,
  sessionData?: SessionData,
  query?: HttpQuery
): Promise<Result> {
  const res = await httpClient.deleteJson<Request, Result>(
    buildUrl(pathItems),
    request,
    sessionData,
    query
  )
  if (isSuccessResponse(res)) return res.body
  throw new ClientError(res)
}

export async function postJson<Request, Result>(
  httpClient: HttpClient,
  pathItems: PathItem[],
  request: Request,
  sessionData?: SessionData,
  query?: HttpQuery,
): Promise<Result> {
  const res = await httpClient.postJson<Request, Result>(
    buildUrl(pathItems),
    request,
    sessionData,
    query,
  )
  if (isSuccessResponse(res)) return res.body
  throw new ClientError(res)
}

export async function postFormDataJson<Request, Result>(
  httpClient: HttpClient,
  pathItems: PathItem[],
  request: Request,
  sessionData?: SessionData,
  query?: HttpQuery,
): Promise<Result> {
  const res = await httpClient.postFormDataJson<Request, Result>(
    buildUrl(pathItems),
    request,
    sessionData,
    query,
  )
  if (isSuccessResponse(res)) return res.body
  throw new ClientError(res)
}

export class DefaultReadOnlyDataClient<TObject extends Identifiable> {
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly pathItems: PathItem[]
  ) {}

  async getAll(sessionData: SessionData): Promise<TObject[]> {
    return getJson<TObject[]>(this.httpClient, this.pathItems, sessionData)
  }

  async getById(sessionData: SessionData, id: number): Promise<TObject> {
    return getJson<TObject>(
      this.httpClient,
      this.pathItems.concat([id]),
      sessionData,
      undefined
    )
  }
}
