export function setBodyScrollable() {
  if (document.documentElement.classList.contains('not-scrollable')) {
    document.documentElement.classList.remove('not-scrollable')
  }
}

export function setBodyNotScrollable() {
  if (!document.documentElement.classList.contains('not-scrollable')) {
    document.documentElement.classList.add('not-scrollable')
  }
}
