import { toNormalForm } from './string'

export const getRelativeUrl = (absoluteUrl: string): string => {
  if (!absoluteUrl) return undefined
  const url = new URL(absoluteUrl)
  const rel = url?.toString()?.substring(url.origin.length)
  return rel
}

export const buildLeagueUrl = (leagueId: number, leagueName?: string) => {
  if (leagueName)
    return `/sports/league/${leagueId}-${encodeURIComponent(toNormalForm(leagueName)?.replace(/ /g, '-'))}`
  return `/sports/league/${leagueId}`
}

export const buildEventUrl = (eventId: number, eventName?: string) => {
  if (eventName)
    return `/sports/event/${eventId}-${encodeURIComponent(
      toNormalForm(eventName)?.replace(/ - /g, '-vs-').replace(/ /g, '-').replace(/,/g, ''),
    )}`
  else return `/sports/event/${eventId}`
}

export const buildCardUrl = (cardId: number, cardName?: string) => {
  if (cardName) return `/sports/card/${cardId}-${encodeURIComponent(toNormalForm(cardName)?.replace(/ /g, '-'))}`
  else return `/sports/card/${cardId}`
}

export const buildCasinoGameUrl = (casinoGameId: number, casinoGameName?: string) => {
  if (casinoGameName)
    return `/casino?play=${casinoGameId}-${encodeURIComponent(toNormalForm(casinoGameName)?.replace(/ /g, '-'))}`
  else return `/casino?play=${casinoGameId}`
}

export const buildCasinoCategoryUrl = (casinoCategoryId: number, casinoCategoryName?: string) => {
  if (casinoCategoryName)
    return `/casino/category/${casinoCategoryId}-${encodeURIComponent(toNormalForm(casinoCategoryName)?.replace(/ /g, '-'))}`
  else return `/casino/${casinoCategoryId}`
}
