import {
  BookBonusResult,
  BranchBetSettings,
  ChangeSecurityQuestionRequest,
  ChangeUserRequest,
  CheckCpfResult,
  DocumentTypes,
  GivenBonusListItem,
  GivenBonusState,
  Language,
  PasswordError,
  RedeemVoucherResult,
  ResetPasswordStatusCode,
  User,
  UserAccountingListItem,
  UserDefinedBetSettings,
  UserDefinedMarketingSettings,
  UserDocument,
  UserResult,
  UserSelfLockErrorCode,
} from '@arland-bmnext/api-data'
import { AffiliateDTO } from '@arland-bmnext/webapps-api-data'
import useSWR, { KeyedMutator } from 'swr'
import { defaultFetcher } from '../util/default-fetcher'
import { handleHttpErrorStatus } from './api-client'
import { AxiosClient } from './axiosclient'
import { PagedResult } from './api-client/core'
import { ChangePasswordRequest, ChangePinRequest, LockDuration, TwoFASettings } from './api-client/account'
import { SessionTimeCheckResult } from '../util/session-time'
import { isLoggedInCustomer } from './user'

export const useOpenBonusCount = (): {
  openBonusCount: number
  mutateOpenBonusCount: (data?: any, shouldRevalidate?: boolean) => Promise<any>
} => {
  const {
    data,
    error,
    mutate: mutateOpenBonusCount,
  } = useSWR<any>('/api/account/open-bonus-count', defaultFetcher, { dedupingInterval: 60000 })

  handleHttpErrorStatus(error?.status)

  return {
    openBonusCount: data,
    mutateOpenBonusCount,
  }
}

export const useNodeBetSettings = (): {
  nodeBetSettings: BranchBetSettings
  mutateNodeBetSettings: (data?: any, shouldRevalidate?: boolean) => Promise<any>
} => {
  const {
    data,
    error,
    mutate: mutateNodeBetSettings,
  } = useSWR<any>('/api/account/node-bet-settings', defaultFetcher, { dedupingInterval: 60000 })

  handleHttpErrorStatus(error?.status)

  return {
    nodeBetSettings: data,
    mutateNodeBetSettings,
  }
}

export const acceptBonus = async (bonusId: number): Promise<BookBonusResult> => {
  const data = await AxiosClient.post<number, BookBonusResult>('/api/account/bonus-accept', { bonusId })
  return data
}

export const rejectBonus = async (bonusId: number): Promise<BookBonusResult> => {
  const data = await AxiosClient.post<number, BookBonusResult>('/api/account/bonus-reject', { bonusId })
  return data
}

export const getUserBonuses = async (
  startIndex: number,
  pageSize: number,
  languageId: number,
  state: GivenBonusState,
): Promise<PagedResult<GivenBonusListItem>> => {
  let query = '?'
  query += 'startIndex=' + startIndex
  query += '&pageSize=' + pageSize
  query += '&languageId=' + languageId
  query += '&state=' + state

  const data = await AxiosClient.get<PagedResult<GivenBonusListItem>>(`/api/account/user-bonuses${query}`)
  return data
}

export const useOpenUserBonuses = (
  languageId: number,
  pageSize: number = 5,
): {
  openBonuses: GivenBonusListItem[]
  mutateOpenBonuses: (data?: any, shouldRevalidate?: boolean) => Promise<any>
  isValidating: boolean
} => {
  let query = '?'
  query += 'startIndex=' + 0
  query += '&pageSize=' + pageSize
  query += '&languageId=' + languageId
  query += '&state=' + GivenBonusState.New

  const {
    data,
    error,
    mutate: mutateOpenBonuses,
    isValidating,
  } = useSWR<PagedResult<GivenBonusListItem>>(languageId ? `/api/account/user-bonuses${query}` : null, defaultFetcher, {
    refreshInterval: 60000,
    dedupingInterval: 60000,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  handleHttpErrorStatus(error?.status)

  return {
    openBonuses: data?.items,
    mutateOpenBonuses,
    isValidating,
  }
}

export const redeemVoucher = async (voucherCode: string): Promise<RedeemVoucherResult> => {
  const data = await AxiosClient.post<string, RedeemVoucherResult>('/api/account/voucher-redeem', { voucherCode })
  return data
}

export const getUserBookings = async (
  accountId: number,
  startDate: string,
  endDate: string,
  startIndex: number,
  pageSize: number,
  languageId: number,
): Promise<PagedResult<UserAccountingListItem>> => {
  let query = '?'
  query += '&accountId=' + accountId
  query += '&startDate=' + startDate
  query += '&endDate=' + endDate
  query += '&startIndex=' + startIndex
  query += '&pageSize=' + pageSize
  query += '&languageId=' + languageId

  const data = await AxiosClient.get<PagedResult<UserAccountingListItem>>(`/api/account/user-bookings${query}`)
  return data
}

export const changeBetSettings = async (request: UserDefinedBetSettings): Promise<UserDefinedBetSettings> => {
  const data = await AxiosClient.put<UserDefinedBetSettings, UserDefinedBetSettings>(
    `/api/account/change-bet-settings`,
    request,
  )
  return data
}

export const changeMarketingSettings = async (
  request: UserDefinedMarketingSettings,
): Promise<UserDefinedMarketingSettings> => {
  const data = await AxiosClient.put<UserDefinedMarketingSettings, UserDefinedMarketingSettings>(
    `/api/account/change-marketing-settings`,
    request,
  )
  return data
}

export const changeUserPassword = async (request: ChangePasswordRequest): Promise<PasswordError> => {
  const data = await AxiosClient.put<ChangePasswordRequest, PasswordError>(`/api/account/change-password`, request)
  return data
}

export const changeUserPin = async (request: ChangePinRequest): Promise<boolean> => {
  const data = await AxiosClient.put<ChangePinRequest, boolean>(`/api/account/change-pin`, request)
  return data
}

export const changePasswordRecoveryQuestion = async (
  request: ChangeSecurityQuestionRequest,
  languageId: number,
): Promise<ResetPasswordStatusCode> => {
  const data = await AxiosClient.put<ChangeSecurityQuestionRequest, ResetPasswordStatusCode>(
    `/api/account/change-recovery-question?languageId=${languageId}`,
    request,
  )
  return data
}

export const changeUserSelflimits = async (request: any): Promise<any> => {
  const data = await AxiosClient.put<any, any>(`/api/account/change-selflimits`, request)
  return data
}

export const changeTfaSettings = async (request: TwoFASettings): Promise<TwoFASettings> => {
  const data = await AxiosClient.put<TwoFASettings, TwoFASettings>(`/api/account/change-tfa-settings`, request)
  return data
}

export const changeUserProfile = async (request: ChangeUserRequest): Promise<UserResult> => {
  const data = await AxiosClient.put<ChangeUserRequest, UserResult>(`/api/account/change-user-profile`, request)
  return data
}

export const lockAccountTemporary = async (lockDuration: LockDuration): Promise<UserSelfLockErrorCode> => {
  const data = await AxiosClient.put<any, UserSelfLockErrorCode>(`/api/account/lock`, { lockDuration })
  return data
}

export const closeAccount = async (): Promise<UserSelfLockErrorCode> => {
  const data = await AxiosClient.put<null, UserSelfLockErrorCode>(`/api/account/close`, {})
  return data
}

export const uploadUserDocument = async (type: DocumentTypes, formData: FormData): Promise<UserDocument> => {
  const data = await AxiosClient.upload(`/api/account/documents?type=${type}`, formData)
  return data
}

export const getUserDocuments = async (startIndex: number, pageSize: number): Promise<PagedResult<UserDocument>> => {
  let query = '?'
  query += 'startIndex=' + startIndex
  query += '&pageSize=' + pageSize

  const data = await AxiosClient.get<PagedResult<UserDocument>>(`/api/account/documents${query}`)
  return data
}

export const resetUserPassword = async (
  userId: number,
  passwordRecoveryAnswer?: string,
): Promise<ResetPasswordStatusCode> => {
  let query = '?'
  query += 'userId=' + userId
  if (passwordRecoveryAnswer) query += '&passwordRecoveryAnswer=' + passwordRecoveryAnswer

  const data = await AxiosClient.get<ResetPasswordStatusCode>(`/api/account/reset-password${query}`)
  return data
}

export const setNewUserPassword = async (newPassword: string, token: string): Promise<any> => {
  const data = await AxiosClient.post<any, PasswordError>(`/api/account/set-new-password`, { newPassword, token })
  return data
}

export const resendVerificationEmail = async (userId: number): Promise<string> => {
  const data = await AxiosClient.post<null, string>('/api/account/resend-verification-email?user=' + userId, null)
  return data
}

export const deleteUserAvatar = async (): Promise<boolean> => {
  const data = await AxiosClient.delete<null, boolean>('/api/account/delete-avatar', null)
  return data
}

export const getNodeLanguages = async (): Promise<{ languages: Language[]; defaultLanguageId: number }> => {
  const data = await AxiosClient.get<{ languages: Language[]; defaultLanguageId: number }>(
    `/api/account/node-languages`,
  )
  return data
}

export const useAffiliateReport = (
  startIndex: number,
  pageSize: number,
  startDate: string,
  endDate: string,
): { data: AffiliateDTO; isValidating: boolean } => {
  let query = '?'
  query += 'startIndex=' + startIndex
  query += '&pageSize=' + pageSize
  query += '&startDate=' + startDate
  query += '&endDate=' + endDate

  const { data, error, isValidating } = useSWR<AffiliateDTO>('/api/account/affiliate-report' + query, defaultFetcher, {
    dedupingInterval: 60000,
  })

  handleHttpErrorStatus(error?.status)

  return {
    data,
    isValidating,
  }
}

export const getAffiliateReport = async (
  startIndex: number,
  pageSize: number,
  startDate: string,
  endDate: string,
): Promise<AffiliateDTO> => {
  let query = '?'
  query += 'startIndex=' + startIndex
  query += '&pageSize=' + pageSize
  query += '&startDate=' + startDate
  query += '&endDate=' + endDate
  return await AxiosClient.get<AffiliateDTO>(`/api/account/affiliate-report${query}`)
}

export const getCpfData = async (cpf: string): Promise<CheckCpfResult> => {
  return await AxiosClient.get<CheckCpfResult>(`/api/account/cpf-data?cpf=${cpf}`)
}

export const useSessionTimeLimitCheck = (
  user: User,
): {
  sessionTimeLimit: SessionTimeCheckResult
  mutateSessionTimeLimit: KeyedMutator<SessionTimeCheckResult>
} => {
  const { data, mutate, error } = useSWR<SessionTimeCheckResult>(
    user && isLoggedInCustomer(user) ? `/api/account/session-limit-check?userId=${user.id}` : null,
    defaultFetcher,
    {
      dedupingInterval: 60000,
      refreshInterval: 60000,
    },
  )

  handleHttpErrorStatus(error?.status)

  return { sessionTimeLimit: data, mutateSessionTimeLimit: mutate }
}

export const renewSessionTimeLimit = async (): Promise<boolean> => {
  return await AxiosClient.post<null, boolean>(`/api/account/session-limit-renewal`, null)
}
