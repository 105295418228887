import { Country, Currency, Language, TimeZone } from "@arland-bmnext/api-data";
import { DefaultReadOnlyDataClient, getJson, HttpClient, ReadOnlyDataClient, SessionData } from "../core";

export class CommonClient {
  public readonly timeZones: ReadOnlyDataClient<TimeZone>
  public readonly countries: ReadOnlyDataClient<Country>
  public readonly languages: ReadOnlyDataClient<Language>
  public readonly currencies: ReadOnlyDataClient<Currency>

  constructor(private readonly httpClient: HttpClient) {
    this.timeZones = new DefaultReadOnlyDataClient<TimeZone>(
      httpClient,
      ['api', 'timezones']
    )
    this.countries = new DefaultReadOnlyDataClient<Country>(
      httpClient,
      ['api', 'countries']
    )
    this.languages = new DefaultReadOnlyDataClient<Language>(
      httpClient,
      ['api', 'languages']
    )
    this.currencies = new DefaultReadOnlyDataClient<Currency>(
      httpClient,
      ['api', 'currencies']
    )
  }

  public async getNodeCountries(sessionData: SessionData): Promise<Country[]> {
    const countries = await getJson<Country[]>(
      this.httpClient,
      ['api', 'node', 'countries'],
      sessionData,
    )
    return countries
  }

  public async getNodeCurrencies(sessionData: SessionData): Promise<Currency[]> {
    const currencies = await getJson<Currency[]>(
      this.httpClient,
      ['api', 'node', 'currencies'],
      sessionData,
    )
    return currencies
  }

  public async getNodeLanguages(sessionData: SessionData): Promise<Language[]> {
    const languages = await getJson<Language[]>(
      this.httpClient,
      ['api', 'node', 'languages'],
      sessionData,
    )
    return languages
  }
}
