import { GameCategory } from '@arland-bmnext/api-data'
import { HttpClient, getJson, SessionData } from '../core'

export class CategoriesClient {
  constructor(private readonly httpClient: HttpClient) {}

  public async getBettableCategories(
    sessionData: SessionData,
    sportId: number,
    startDate: string,
    endDate: string,
    languageId: number = 1
  ): Promise<GameCategory[]> {
    const categories = await getJson<GameCategory[]>(
      this.httpClient,
      ['api', 'categories', 'bettable', sportId, startDate, endDate, languageId],
      sessionData
    )
    return categories
  }
}
