import { Currency, Language } from '@arland-bmnext/api-data'
import { createContext, useEffect, useState, useContext } from 'react'
import { CommonData } from '../lib/common'
import useTranslation from 'next-translate/useTranslation'
import { OddValueFormat, useOddsFormat } from '../hooks/useOddsFormat'

type CommonContextProps = CommonData & {
  getCurrencyById?: (id: number) => Currency
  getLanguageByShortSign?: (shortSign: string) => Language
  currentLanguage?: Language
  oddsFormat?: OddValueFormat
  setOddsFormat?: (format: OddValueFormat, saveToUserSetting?: boolean) => Promise<void>
}

export const CommonContext = createContext<CommonContextProps | undefined>(undefined)

export const useCommonContext = () => {
  const value = useContext(CommonContext)
  if (process.env.NODE_ENV !== 'production') {
    if (value === undefined) {
      throw new Error('useCommonContext must be wrapped in a <CommonProvider />')
    }
  }
  return value
}

const CommonProvider = ({ children, currencies, languages, timeZones, countries, nodeCountries }) => {
  const { lang } = useTranslation()
  const [oddsFormat, setOddsFormat] = useOddsFormat()
  const [currentLanguage, setCurrentLanguage] = useState<Language>(null)
  const [commonData] = useState<CommonData>({
    currencies,
    languages,
    timeZones,
    countries,
    nodeCountries,
  })
  

  useEffect(() => {
    const _language = getLanguageByShortSign(lang)
    if (_language) setCurrentLanguage(_language)
  }, [lang])

  const getCurrencyById = (id: number) => {
    return commonData?.currencies?.find((x) => x.id === id)
  }

  const getLanguageByShortSign = (shortSign: string) => {
    return commonData?.languages?.find((x) => x.shortSign === shortSign)
  }

  const value = {
    languages: commonData?.languages,
    currencies: commonData?.currencies,
    timeZones: commonData?.timeZones,
    countries: commonData?.countries,
    nodeCountries: commonData?.nodeCountries,
    getLanguageByShortSign: getLanguageByShortSign,
    currentLanguage,
    getCurrencyById: getCurrencyById,
    oddsFormat,
    setOddsFormat,
  }

  return <CommonContext.Provider value={value}>{children}</CommonContext.Provider>
}

export default CommonProvider
