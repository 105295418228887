import { Game, GameCompetitor, GamePeriod } from '@arland-bmnext/api-data'
import { HttpClient, getJson, PagedResult, SessionData } from '../core'

export class GamesClient {
  constructor(private readonly httpClient: HttpClient) {}

  public async getGames(
    sessionData: SessionData,
    startDate: string,
    endDate: string,
    languageId: number = 1,
    marketTypeIds?: string[] | number[],
    sportId?: number,
    gameMode?: number,
    categoryIds?: string[] | number[],
    onlyTop?: boolean,
    pageNumber?: number,
    pageSize?: number,

  ): Promise<PagedResult<Game>> {
    const query = {} as any

    if (marketTypeIds) query['marketTypeIds'] = marketTypeIds.join(',')
    if (sportId) query['sportId'] = sportId
    if (gameMode) query['gameMode'] = gameMode
    if (categoryIds) query['categoryIds'] = categoryIds.join(',')
    if (onlyTop) query['onlyTop'] = onlyTop
    if (pageNumber) query['pageNumber'] = pageNumber
    if (pageSize) query['pageSize'] = pageSize

    const sports = await getJson<PagedResult<Game>>(
      this.httpClient,
      ['api', 'games', startDate, endDate, languageId],
      sessionData,
      query
    )

    return sports
  }

  public async getGamesByLeagueId(
    sessionData: SessionData,
    leagueId: number,
    languageId: number = 1,
    startDate?: string,
    endDate?: string,
    marketTypeIds?: string[] | number[],
    gameMode?: number,
    onlyTop?: boolean,
    pageNumber?: number,
    pageSize?: number,
  ): Promise<PagedResult<Game>> {
    const query = {} as any

    if (startDate) query['startDate'] = startDate
    if (endDate) query['endDate'] = endDate
    if (marketTypeIds) query['marketTypeIds'] = marketTypeIds.join(',')
    if (gameMode) query['gameMode'] = gameMode
    if (onlyTop) query['onlyTop'] = onlyTop
    if (pageNumber) query['pageNumber'] = pageNumber
    if (pageSize) query['pageSize'] = pageSize

    const sports = await getJson<PagedResult<Game>>(
      this.httpClient,
      ['api', 'games', leagueId, languageId],
      sessionData,
      query
    )

    return sports
  }

  public async getGameById(sessionData: SessionData, gameId: number, languageId: number = 1, marketTypeIds?: string[] | number[]): Promise<Game> {
    const query = { langId: languageId } as any
    if (marketTypeIds) query['marketTypeIds'] = marketTypeIds.join(',')

    const game = await getJson<Game>(this.httpClient, ['api', 'games', gameId], sessionData, query)
    return game
  }

  public async getGamePeriods(sessionData: SessionData, languageId: number = 1): Promise<GamePeriod[]> {
    const query = { langId: languageId } as any
    const periods = await getJson<GamePeriod[]>(this.httpClient, ['api', 'games', 'periods'], sessionData, query)
    return periods
  }

  public async getGameCompetitors(sessionData: SessionData, gameId: number): Promise<GameCompetitor[]> {
    const competitors = await getJson<GameCompetitor[]>(this.httpClient, ['api', 'games', gameId, 'competitors'], sessionData)
    return competitors
  }
}
