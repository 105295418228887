import { SessionData as SessionData } from './types'

export type ClientInit = {
  readonly baseUrl: string
  readonly externalBaseUrl: string
  readonly application: {
    name: string
    guid: string
  }
}

export type ErrorStatus = 'not-found' | 'unauthorized' | 'api-error' | 'session-timeout'

export type ErrorResponse = {
  status: ErrorStatus
  statusCode: number
  statusText: string
}

export type SuccessResponse<Body> = {
  status: 'ok'
  body: Body
}

export type ClientResponse<Body> = ErrorResponse | SuccessResponse<Body>

export type HttpQuery = { [key: string]: string | string[] }

export interface HttpClient {
  readonly init: ClientInit

  postJson<Request, Result>(
    relativeUrl: string,
    request: Request,
    sessionId?: SessionData,
    query?: HttpQuery,
  ): Promise<ClientResponse<Result>>

  postFormDataJson<Request, Result>(
    relativeUrl: string,
    request: Request,
    sessionId?: SessionData,
    query?: HttpQuery,
  ): Promise<ClientResponse<Result>>

  putJson<Request, Result>(
    relativeUrl: string,
    request: Request,
    sessionId?: SessionData,
    query?: HttpQuery
  ): Promise<ClientResponse<Result>>

  deleteJson<Request, Result>(
    relativeUrl: string,
    request: Request,
    sessionId?: SessionData,
    query?: HttpQuery
  ): Promise<ClientResponse<Result>>

  getJson<Result>(relativeUrl: string, sessionId?: SessionData, query?: HttpQuery): Promise<ClientResponse<Result>>
}

export function isSuccessResponse<Result>(response: ClientResponse<Result>): response is SuccessResponse<Result> {
  return response.status == 'ok'
}
